import { MapPort } from '../renderTypes';
import { Port } from '../types/api';

export const mapPortToMapPort = (item: Port) => ({
  name: item.name,
  options: {
    position: { lat: item.latitude, lng: item.longitude },
  },
  portCode: item.portCode,
  image: item.portImage,
  isFreight: item.isFreight || false,
  hideOnMap: item.hideOnMap || false,
} as MapPort);
