import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getLocationsServedByLocationCodeQuery } from './queries/location';
import { apolloClient } from '../services/ApolloClient';
import { Location } from '../apiTypes';
import { mapRoutesToMapRoutes, mapRouteToRouteServed } from '../maps';
import { RoutesServedType } from '../types/RenderTypes';

provideApolloClient(apolloClient);

const locationsServed = ref<Location>();
const isLoading = ref<boolean>(false);

const processResult = ((queryResult, document) => {
  if (document.value.loc?.source.body === getLocationsServedByLocationCodeQuery.loc?.source.body) {
    locationsServed.value = queryResult.data?.locations[0];
  }
});

export const locationStoreDefinition = {
  state: () => ({
    locationsServed,
    isLoading,
  }),
  actions: {
    async requestLocationsServed(locationCode: string) {
      const {
        onResult,
        onError,
        loading,
        document,
      } = useQuery(getLocationsServedByLocationCodeQuery, {
        locationCode,
      });
      isLoading.value = loading.value;

      onError((error) => {
        isLoading.value = loading.value;
        console.log('requestLocationsServed: error', error);
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        processResult(queryResult, document);
      });
    },
  },
  getters: {
    getLocationsServed() {
      return locationsServed.value?.routes
        ? locationsServed.value.routes
          .filter((route) => !route.isFreight && !route.hideOnMap)
          .map((route) => ({
            ...mapRouteToRouteServed(route),
            routeMap: [mapRoutesToMapRoutes(route)],
          } as RoutesServedType))
        : undefined;
    },
  },
};

export const useLocationStore = defineStore('locationStore', locationStoreDefinition);
