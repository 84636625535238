import { formatInTimeZone } from 'date-fns-tz';
import { timetableResultsUrl, timezone } from '../envVariables';
import { RoutesServedType } from '../types/RenderTypes';
import { Route } from '../apiTypes';
import { mapPortToLocation } from './mapPortToLocation';

export const mapRouteToRouteServed = (route: Route) => {
  const today = formatInTimeZone(new Date(), timezone, 'yyyy-MM-dd');

  return ({
    routeCode: route.routeCode,
    timetableLink: route.ports.length <= 2
      ? `${timetableResultsUrl}/#/timetable-search/${route.ports[0].name}/${route.ports[1].name}/${today}`
      : undefined,
    keyInformation: route.keyInformation,
    portList: route.ports.map(mapPortToLocation).filter((port) => !port.isFreight && !port.hideOnMap),
    mapPort: route.ports.map(mapPortToLocation).filter((port) => !port.isFreight && !port.hideOnMap),
    vesselList: route.vessels,
  } as RoutesServedType);
};
