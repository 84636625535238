import gql from 'graphql-tag';

const getLocationsServedByLocationCodeQuery = gql`
query
($locationCode: String)
{
    locations (where: {locationCode: {eq: $locationCode}})
    {
      name
      locationUrl
      soundbiteUrl
      description
      activities {
        description
        icon
      }
      routes{
        routeCode
        keyInformation
        polyline
        isFreight
        hideOnMap
        vessels{
          name
          vesselUrl
          vesselCode
        }
        ports{
          name
          portCode
          portUrl
          longitude
          latitude
          location{
            name
          }
        }
      }
    }
  }
`;

export {
  getLocationsServedByLocationCodeQuery,
};
