export * from './routeSubscription';
export * from './channel';

/* eslint-disable no-use-before-define */
export enum SubStatus {
  AllSailingsCancelled = 'ALL_SAILINGS_CANCELLED',
  BeAware = 'BE_AWARE',
  Disruptions = 'DISRUPTIONS',
  None = 'NONE'
}

export enum RouteStatusCategory {
  AllSailingsCancelled = 'ALL_SAILINGS_CANCELLED',
  BeAware = 'BE_AWARE',
  Disruptions = 'DISRUPTIONS',
  Normal = 'NORMAL'
}

export enum StatusCategory {
  Information = 'INFORMATION',
  Sailing = 'SAILING',
  Service = 'SERVICE',
  Undefined = 'UNDEFINED'
}

export enum OfflineTimetableType {
  Summer = 'Summer',
  Winter = 'Winter',
  Tidal = 'Tidal'
}

export interface Activity {
  description: string;
  icon: string;
}
export interface Location {
  name: string;
  latitude?: number;
  longitude?: number;
  locationCode?: string;
  locationImage?: string;
  locationUrl?: string;
  soundbiteUrl?: string;
  description?: string;
  activities?: Array<Activity>;
  routes?: Array<Route>;
  isFreight?: boolean;
  hideOnMap?: boolean;
}
export interface Port {
  portCode: string;
  name?: string;
  order: number;
  latitude: number;
  longitude: number;
  portImage?: string;
  description?: string;
  portUrl?: string;
  address?: string;
  location?: Location;
  routes?: Array<Route>;
  isFreight?: boolean;
  hideOnMap?: boolean;
}

export interface Vessel {
  name: string;
  vesselCode: string;
  vesselUrl?: string;
  routes?: Array<Route>;
}

export interface RouteStatus {
  id: string;
  title: string;
  detail: string;
  status: StatusCategory;
  subStatus: SubStatus;
  updatedAtDateTime: string;
  nextReviewDateTime: string;
  startDateTime: string;
  endDateTime: string;
}

export interface Route {
  name: string;
  id?: string;
  routeCode: string;
  ports: Port[];
  polyline?: string;
  routeStatuses: RouteStatus[];
  location?: Location;
  originPort?: Port;
  destinationPort?: Port;
  description?: string;
  vessels?: Vessel[];
  keyInformation?: string;
  status: RouteStatusCategory;
  isFreight?: boolean;
  hideOnMap?: boolean;
}

export interface Routes {
  routes: Array<Route>;
}

export interface Leg {
  originPort: Port;
  destinationPort: Port;
  order?: number;
  departureDateTime: string;
  arrivalDateTime?: string;
}

export interface PortPairSailing {
  sailingDate: string;
  route: Route;
  departureDateTime: string;
  arrivalDateTime: string;
  rescheduledDepartureDateTime: string;
  rescheduledArrivalDateTime: string;
  status: string;
  sailingLegs: Leg[];
  vessel: Vessel;
}

export interface Sailing {
  sailingDate: string;
  route: Route;
  departureDateTime: string;
  arrivalDateTime: string;
  rescheduledDepartureDateTime?: string;
  rescheduledArrivalDateTime?: string;
  status: string;
  legs: Leg[];
  vessel: Vessel;
}

export interface Sailings {
  sailings: Sailing[];
}

export interface OfflineTimetable{
  title: string;
  route: Route;
  validFrom: string;
  validUntil: string;
  pdfUrl: string;
  imageUrl: string;
  key: string;
  dateAdded?: string;
  lastUpdated: string;
}

export interface OfflineTimetableSaved extends OfflineTimetable{
  fileName: string;
}

export interface PortPair{
  originPort: string;
  originPortCode: string;
  originLocation: string;
  destinationPort: string;
  destinationPortCode: string;
  destinationLocation: string;
  route: Route;
}

export interface TimetableSearch {
  from: string;
  to: string;
  fromDate: string;
  toDate?: string;
  routeCode: string;
}

export interface RouteInformation {
  id?: string;
  name: string;
  polyline: string;
  ports: Port[];
  routeInformation: string;
  routeCode?: string;
  textCode?: string;
  location?: Location;
}

export interface OfflineTimetablesResult {
  timetableType: OfflineTimetableType;
  title: string;
  releaseDetail: Date;
  pdfUrl: string;
  imageUrl: string;
}

export interface RouteMapCoords {
  name: string;
  routeCode: string;
  polyline: string;
  ports: Array<Port>;
  location: Location;
  originPort: Port;
  destinationPort: Port;
  isFreight: boolean;
  hideOnMap: boolean;
}
