import * as DOMPurify from 'dompurify';
import { marked } from 'marked';
import { assetBaseUrl } from '../envVariables';

export const updateMediaPaths = (input: string, assetsBaseUrl: string) : string => {
  const mediaMatchRegex = /\[(\d+)\]\s*:\s*\/media\//g;
  return input.replace(mediaMatchRegex, `[$1]: ${assetsBaseUrl}/media/`);
};

export const markdownToHtml = (markdown: string | undefined): string => {
  if (!markdown) {
    return '';
  }

  const markdownWithRelativePathsUpdated = updateMediaPaths(markdown, assetBaseUrl);
  return DOMPurify.sanitize(marked.parse(markdownWithRelativePathsUpdated, {
    mangle: false,
    headerIds: false,
  }));
};
