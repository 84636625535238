import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import {
  getRoutesWithStatusQuery,
  getRouteInformationQuery,
  getRouteInformationByPortsQuery,
  getAllRoutesMapCoordsQuery,
  getRoutesServedByRouteCodeQuery,
  getRouteMapByRouteCode,
  getAllRoutesQuery,
} from './queries/routes';
import {
  Route, RouteInformation, RouteMapCoords,
} from '../types/api';
import { apolloClient } from '../services/ApolloClient';
import {
  mapLocationToDestination,
  mapPortToLocation,
  mapPortToMapPort,
  mapRouteByPortsToRoutes,
  mapRoutesToMapRoutes,
  mapRoutesWithStatusToLocation,
  mapRoutesWithStatusToRoute,
  mapRouteToRouteServed,
} from '../maps';
import { RoutesServedType } from '../types/RenderTypes';

provideApolloClient(apolloClient);

const routesWithStatus = ref<Array<Route>>();
const routeInformation = ref<Array<RouteInformation>>();
const routesByPorts = ref<Array<RouteInformation>>();
const firstRoutesByPorts = ref<RouteInformation>();
const routesMapCoords = ref<Array<RouteMapCoords>>();
const routeServed = ref<Route>();
const routeInfoMap = ref<Route>();
const allRoutes = ref<Array<RouteInformation>>();

const allRoutesError = ref(false);

// Where race condition occur isLaoding may be false when it should be true
const isLoading = ref<boolean>(false);

const processResult = ((queryResult, document) => {
  if (!queryResult.data || queryResult.loading) {
    return;
  }

  if (document.value.loc?.source.body === getRoutesWithStatusQuery.loc?.source.body) {
    routesWithStatus.value = queryResult.data.routes;
  }

  if (document.value.loc?.source.body === getRouteInformationQuery.loc?.source.body) {
    routeInformation.value = queryResult.data.routes;
  }

  if (document.value.loc?.source.body === getRouteInformationByPortsQuery.loc?.source.body) {
    routesByPorts.value = queryResult.data.routes;
    firstRoutesByPorts.value = routesByPorts.value?.[0];
  }

  if (document.value.loc?.source.body === getAllRoutesMapCoordsQuery.loc?.source.body) {
    routesMapCoords.value = queryResult.data.routes;
  }

  if (document.value.loc?.source.body === getRoutesServedByRouteCodeQuery.loc?.source.body) {
    routeServed.value = queryResult.data?.routes[0];
  }

  if (document.value.loc?.source.body === getRouteMapByRouteCode.loc?.source.body) {
    routeInfoMap.value = queryResult.data?.routes[0];
  }
});

export const routeStoreDefinition = {
  state: () => ({
    routesWithStatus,
    routeInformation,
    routesByPorts,
    firstRoutesByPorts,
    routesMapCoords,
    routeServed,
    routeInfoMap,
    allRoutes,
    allRoutesError,
    isLoading,
  }),
  actions: {
    async requestRoutesWithStatus() {
      const {
        refetch,
        onResult,
        onError,
        loading,
        document,
      } = useQuery(
        getRoutesWithStatusQuery,
        {},
      );
      isLoading.value = loading.value;

      onError((error) => {
        isLoading.value = loading.value;
        console.log('requestRoutesWithStatus: error', error);
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        processResult(queryResult, document);
      });

      await refetch();
    },
    async requestRouteInformation(originPortName: string, destinationPortName: string) {
      const {
        refetch,
        onResult,
        onError,
        loading,
        document,
      } = useQuery(
        getRouteInformationQuery,
        {
          originPortName,
          destinationPortName,
        },
      );
      isLoading.value = loading.value;

      onError((error) => {
        isLoading.value = loading.value;
        console.log('requestRouteInformation: error', error);
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        processResult(queryResult, document);
      });

      await refetch();
    },
    async requestRouteByPorts(originPortName: string, destinationPortName: string) {
      const {
        refetch,
        onResult,
        onError,
        loading,
        document,
      } = useQuery(
        getRouteInformationByPortsQuery,
        {
          originPortName,
          destinationPortName,
        },
      );
      isLoading.value = loading.value;

      onError((error) => {
        isLoading.value = loading.value;
        console.log('requestRouteByPorts: error', error);
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        processResult(queryResult, document);
      });

      await refetch();
    },
    async requestAllRoutesMapCoords() {
      const {
        refetch,
        onResult,
        onError,
        loading,
        document,
      } = useQuery(
        getAllRoutesMapCoordsQuery,
        {},
      );
      isLoading.value = loading.value;

      onError((error) => {
        isLoading.value = loading.value;
        console.log('requestAllRoutesMapCoords: error', error);
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        processResult(queryResult, document);
      });

      await refetch();
    },
    async requestRouteServed(routeCode: string) {
      const {
        onResult,
        onError,
        loading,
        document,
      } = useQuery(
        getRoutesServedByRouteCodeQuery,
        { routeCode },
      );
      isLoading.value = loading.value;

      onError((error) => {
        isLoading.value = loading.value;
        console.log('requestRouteServed: error', error);
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        processResult(queryResult, document);
      });
    },
    requestRouteMapByRouteCode(routeCode: string) {
      const {
        refetch,
        onResult,
        onError,
        loading,
        document,
      } = useQuery(
        getRouteMapByRouteCode,
        { routeCode },
      );
      isLoading.value = loading.value;

      onError((error) => {
        isLoading.value = loading.value;
        console.log('requestRouteMap: error', error);
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        processResult(queryResult, document);
      });

      refetch();
    },
    async requestAllRoutes() {
      const {
        refetch,
        onResult,
        onError,
        loading,
      } = useQuery(
        getAllRoutesQuery,
        {},
      );
      isLoading.value = loading.value;
      allRoutesError.value = false;

      onError((error) => {
        isLoading.value = loading.value;
        console.log('requestAllRoutes: error', error);
        allRoutesError.value = true;
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        allRoutes.value = queryResult.data?.routes;
        allRoutesError.value = false;
      });

      await refetch();
    },
    updateRouteInformation: (newRouteInfo) => {
      routeInformation.value = newRouteInfo;
    },
    getRoute(routeCode: string) {
      return routesWithStatus.value?.filter((route) => route.routeCode === routeCode)?.[0];
    },
  },
  getters: {
    getRouteInformation() {
      if (!routeInformation.value) return undefined;
      return routeInformation.value[0];
    },
    getMapRoutesWithStatus() {
      return (routesWithStatus.value || [])
        .filter((route: Route) => !route.isFreight && !route.hideOnMap)
        .map(mapRoutesWithStatusToRoute);
    },
    getMapLocationsWithStatus() {
      return (routesWithStatus.value || [])
        .filter((route: Route) => !route.isFreight && !route.hideOnMap)
        .map(mapRoutesWithStatusToLocation).flat();
    },
    getMapRoutesByPortName() {
      if (!routesByPorts.value) return undefined;
      return routesByPorts.value?.map(mapRouteByPortsToRoutes);
    },
    getMapPortsByPortName() {
      if (!routesByPorts.value || routesByPorts.value.length === 0) return undefined;
      const { ports } = routesByPorts.value[0];

      return ports.map(mapPortToLocation);
    },
    getAllMapRoutesNoStatus() {
      return (routesMapCoords.value || [])
        .filter((route: RouteMapCoords) => !route.isFreight && !route.hideOnMap)
        .map(mapRoutesToMapRoutes);
    },
    getAllMapPortsNoStatus() {
      return (routesMapCoords.value || [])
        .filter((route: RouteMapCoords) => !route.isFreight && !route.hideOnMap)
        .map((item) => item.ports.map(mapPortToMapPort))
        .flat()
        .filter((port) => !port.isFreight && !port.hideOnMap);
    },
    getAllMapDestinationsNoStatus() {
      return (routesMapCoords.value || [])
        .filter((route: RouteMapCoords) =>
          !route.isFreight && !route.hideOnMap
          && !route.location.isFreight && !route.location.hideOnMap)
        .map((item) => mapLocationToDestination(item.location));
    },
    getRoutesServed() {
      return routeServed.value
        ? ({
          ...mapRouteToRouteServed(routeServed.value),
          routeMap: [mapRoutesToMapRoutes(routeServed.value)],
        } as RoutesServedType)
        : undefined;
    },
  },
};

export const useRoutesStore = defineStore('routesStore', routeStoreDefinition);
