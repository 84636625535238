import gql from 'graphql-tag';

const getServiceStatusQuery = gql`
  query {
    routes {
      name
      id
      routeCode
      ports {
        portCode
        name
        order
        isFreight
        hideOnMap
      }
      routeStatuses {
        id
        title
        status
        subStatus
        updatedAtDateTime
      }
      location {
        name
      }
      status
      isFreight
      hideOnMap
    }
  }`;

const getRouteServiceStatusQuery = gql`
  query ($routeCode: String) {
    routes (where: {routeCode: { eq: $routeCode} }) {
      name
      id
      routeCode
      ports {
        portCode
        name
        order
        isFreight
        hideOnMap
      }
      routeStatuses {
        id
        title
        status
        subStatus
        title
        startDateTime
        endDateTime
        detail
        nextReviewDateTime
        updatedAtDateTime
      }
      location {
        name
      }
      status
      isFreight
      hideOnMap
    }
  }`;

const getRoutesQuery = gql`
  query ($routeName: String)  {
    routes (where: {searchKey: { contains: $routeName} }) {
      name
      id
      routeCode
      ports {
        portCode
        name
        order
        longitude
        latitude
        isFreight
        hideOnMap
      }
      routeStatuses {
        id
        title
        detail
        status
        subStatus
        updatedAtDateTime
        nextReviewDateTime
      }
      location {
        name
      }
      status
      isFreight
      hideOnMap
    }
  }`;

const getRouteSuggestionQuery = gql`
  query ($routeName: String)  {
    routes (where: {searchKey: { contains: $routeName} })
  {
      name
      id
      ports {
        portCode
        name
        order
      }
      location {
        name
      }
      status
    }
  }`;

export {
  getServiceStatusQuery,
  getRouteServiceStatusQuery,
  getRoutesQuery,
  getRouteSuggestionQuery,
};
