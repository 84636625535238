export const timezone = process.env.VUE_APP_TIMEZONE ?? '';
export const ticketingUrl = process.env.VUE_APP_TICKETING_URL ?? '';
export const routeInformationUrl = process.env.VUE_APP_ROUTE_INFO_URL ?? '';

export const b2cClientId = process.env.VUE_APP_B2C_CLIENT_ID ?? '';
export const b2cTenant = process.env.VUE_APP_B2C_TENANT ?? '';
export const b2cHostname = process.env.VUE_APP_B2C_HOSTNAME ?? '';
export const b2cLoginPolicy = process.env.VUE_APP_B2C_LOGIN_POLICY ?? '';
export const b2cRedirectUri = process.env.VUE_APP_B2C_REDIRECT_URL ?? '';
export const b2cPostLogoutRedirectUri = process.env.VUE_APP_B2C_POST_LOGOUT_REDIRECT_URL ?? '';
export const b2cApiScope: string = process.env.VUE_APP_B2C_API_SCOPE ?? '';

export const dictionaryUrl = process.env.VUE_APP_DICTIONARY_URL ?? '';
export const umbracoBaseUrl = process.env.VUE_APP_UMBRACO_CONSUMER_BASE || '';
export const assetBaseUrl = process.env.VUE_APP_ASSETS_BASE_URL || '';

export const b2cScopes = [
  'openid',
  'offline_access',
  b2cApiScope,
];
