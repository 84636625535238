import {
  mapPolyline,
  mapPort,
  mapRouteStatusCategoryToColour,
  parseRouteStatusByStatusCategory,
  sortPorts,
} from '.';
import { GoogleMapsRoute } from '..';
import {
  Route as ApiRoute,
} from '../types/api';

export const mapRoutesWithStatusToRoute = ((item: ApiRoute) => ({
  name: item.location?.name,
  routeCode: item.routeCode,
  status: parseRouteStatusByStatusCategory(item.status),
  ports: (item.ports || [])
    .filter((port) => !port.isFreight && !port.hideOnMap)
    .map(mapPort).sort(sortPorts),
  options: {
    path: item?.polyline ? JSON.parse(item?.polyline)?.map(mapPolyline) : [],
    geodesic: true,
    strokeColor: mapRouteStatusCategoryToColour(item.status),
    strokeOpacity: 1.0,
    strokeWeight: 3,
  },
}) as GoogleMapsRoute);
